import React from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import { Layout, renderModules } from '../../components';
import { HeroBackgroundImage } from '../../components/images';
import RichText, { renderFlatRichText } from '../../components/rich-text';
import CtaButton from '../../components/cta-button';
import './index.scss';

export default function EventModel ({ path, data }) {
  const {
    title, heroText, modules, metaDescription, heroBackgroundImage, aboutTheEvent,
    startDate, endDate, cityStateCountry, registrationUrl, embeddedMapPlaceQuery, ogImage
  } = data.contentfulEvent;

  const dateFmt = new Intl.DateTimeFormat(undefined, {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  let startDateRendered = null;

  if (startDate) {
    startDateRendered = (
      <time className="start-date" dateTime={startDate}>
        {dateFmt.format(new Date(startDate))}
      </time>
    );
  }

  let endDateRendered = null;

  if (endDate) {
    endDateRendered = (
      <time className="start-date" dateTime={endDate}>
        {dateFmt.format(new Date(endDate))}
      </time>
    );
  }

  let datesRendered = null;

  if (startDateRendered && endDateRendered) {
    datesRendered = ( <span className="dates">{startDateRendered} - {endDateRendered}</span> );
  } else if (startDateRendered && !endDateRendered) {
    datesRendered = ( <span className="dates">{startDateRendered}</span> );
  } else if (!startDateRendered && endDateRendered) {
    datesRendered = ( <span className="dates">{endDateRendered}</span> );
  }

  let locationRendered = null;

  if (cityStateCountry) {
    locationRendered = ( <span className="location">{cityStateCountry}</span> );
  }

  let ctaButtonRendered = null;

  if (registrationUrl) {
    ctaButtonRendered = ( <CtaButton ctaText="Register Now" ctaTarget={registrationUrl} /> );
  }

  let googleMapRendered = null;

  if (embeddedMapPlaceQuery) {
    let iframeSrcParams = new URLSearchParams({
      key: 'AIzaSyAwWB_FR-y9uEfcLSR0uxYoY_Vqi6v3ekA',
      q: embeddedMapPlaceQuery,
      zoom: 10
    });

    googleMapRendered = (
      <section className="event-location-map">
        <iframe
          frameBorder="0"
          style={{ width: '100%', height: '80vh', border: '0' }}
          src={`https://www.google.com/maps/embed/v1/place?${iframeSrcParams}`}
          allowFullScreen={true}
        ></iframe>
      </section>
    );
  }

  return (
    <Layout currentPath={path}>
      <Helmet>
        <title>{title?.title}</title>
        <meta name="description" content={metaDescription?.metaDescription} />
        <meta name="og:title" content={title?.title} />
        <meta name="og:description" content={metaDescription?.metaDescription} />
        <meta name="og:image" content={ogImage?.fixed?.src} />
      </Helmet>

      <section className="hero hero-event">
        <HeroBackgroundImage
          heroBackgroundImage={heroBackgroundImage}
          colorWash={false}
          overlay={true}
        />
        <div className="container hero__inner">
          <p className="hero-category-subtitle">Event</p>
          <h2>{renderFlatRichText({ node: heroText })}</h2>
          <p className="hero-category-subtitle">
            {datesRendered}
            {locationRendered}
          </p>
        </div>
      </section>

      <section className="event-details">
        <div className="about-the-event">
          <h4>About the event</h4>
          <RichText node={aboutTheEvent} />
        </div>

        <div className="event-registration">
          <h2>Event Registration</h2>

          <h5>{title?.title}</h5>

          {datesRendered}
          {locationRendered}
          {ctaButtonRendered}
        </div>
      </section>

      {googleMapRendered}

      {renderModules(modules)}
    </Layout>
  );
}

export const pageQuery = graphql`
query EventById($id: String!) {
  contentfulEvent(id: {eq: $id}) {
    startDate, endDate, cityStateCountry, registrationUrl, embeddedMapPlaceQuery
    title { title }
    metaDescription { metaDescription }
    heroText { raw }
    aboutTheEvent { raw }

    ogImage: heroBackgroundImage {
      ...pageOgImageFields
    }

    heroBackgroundImage {
      ...heroBackgroundImageFields
    }

    modules {
      __typename
      ... on ContentfulArticleContentModule {
        ...articleContentModuleFields
      }
      ... on ContentfulBeyondZeroHarmModule {
        ...beyondZeroHarmModuleFields
      }
      ... on ContentfulContactModule {
        ...contactModuleFields
      }
      ... on ContentfulCtaArrowsModule {
        ...ctaArrowsModuleFields
      }
      ... on ContentfulDownloadModule {
        ...downloadModuleFields
      }
      ... on ContentfulFeatureModule {
        ...featureModuleFields
      }
      ... on ContentfulFeaturedResourcesModule {
        ...featuredResourcesModuleFields
      }
      ... on ContentfulFeaturedSolutionsModule {
        ...featuredSolutionsModuleFields
      }
      ... on ContentfulImageVideoModule {
        ...imageVideoModuleFields
      }
      ... on ContentfulImagesWithArrowModule {
        ...imagesWithArrowModuleFields
      }
      ... on ContentfulLeadershipModule {
        ...leadershipModuleFields
      }
      ... on ContentfulPrimaryContentModule {
        ...primaryContentModuleFields
      }
      ... on ContentfulQuoteModule {
        ...quoteModuleFields
      }
      ... on ContentfulSideBlockModule {
        ...sideBlockModuleFields
      }
      ... on ContentfulTwoColumnModule {
        ...twoColumnModuleFields
      }
    }
  }
}
`;
